<div class="login-box">
  <img width="56" height="32" ngSrc="assets/img/pozi.svg" alt="Pozi logo" />
  @if (!displayOauthError) {
    <span i18n>Sign in with Pozi account</span>

    <form (ngSubmit)="login()" [formGroup]="formGroup">
      <div class="input" [class.has-error]="formGroup.get('username')!.invalid">
        <i class="material-symbols-rounded">account_circle</i>
        <input
          formControlName="username"
          id="username"
          name="username"
          type="email"
          placeholder="E-mail address"
          i18n-placeholder
        />
      </div>
      <div class="input" [class.has-error]="formGroup.get('password')!.invalid">
        <i class="material-symbols-rounded">lock_open</i>
        <input
          formControlName="password"
          id="password"
          name="password"
          type="password"
          placeholder="Password"
          i18n-placeholder
        />
        @if (formGroup.get('password')!.invalid) {
          <i
            class="material-symbols-rounded error-icon"
            title="Invalid password"
            i18n-title
            >error</i
          >
        }
      </div>
      @if (formGroup.hasError('invalidCreds') && formGroup.dirty) {
        <div class="error">
          <i class="material-symbols-rounded">error</i>
          <span i18n>Invalid e-mail or password</span>
        </div>
      }
      @if (formGroup.hasError('loginFailed') && formGroup.dirty) {
        <div class="error">
          <i class="material-symbols-rounded">error</i>
          <span i18n
            >Failed to log in <br />
            Please contact your IT administrator</span
          >
        </div>
      }
      <button [disabled]="formGroup.invalid" type="submit">
        <i class="material-symbols-rounded">login</i>
        <span i18n>Login</span>
      </button>
      @if (oauthProviders && oauthProviders.length > 0) {
        <div class="oauth">
          <span i18n>Sign in with corporate account</span>
          @for (provider of oauthProviders; track $index) {
            <button type="button" (click)="oauthLogin($index)">
              <i class="material-symbols-rounded">login</i>
              <span>{{ provider.label }}</span>
            </button>
          }
        </div>
      }
    </form>
  } @else {
    <span i18n>Sign in with corporate account</span>
    <div class="error">
      <i class="material-symbols-rounded">error</i>
      <span i18n
        >Sign in with corporate account was not successful<br />Please contact
        your IT administrator</span
      >
    </div>
    <button class="back" (click)="displayOauthError = false" type="button">
      <i class="material-symbols-rounded">arrow_back</i>
      <span i18n>Back</span>
    </button>
  }
  <pozi-language-switch></pozi-language-switch>
</div>

<div class="version">
  <i class="material-symbols-rounded">info</i>
  <span>Pozi {{ environment.about.version }}</span>
</div>
