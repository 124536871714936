import { NgOptimizedImage } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@pozi/authmodule';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { CompanyUser, ICompanyUser } from '../models/company-user';
import { LanguageSwitchComponent } from '../shared/language/language-switch/language-switch.component';
import { SharedVariablesService } from '../shared/shared-variables/shared-variables.service';

@Component({
  selector: 'pozi-login',
  standalone: true,
  imports: [ReactiveFormsModule, NgOptimizedImage, LanguageSwitchComponent],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private redirect?: string;

  oauthProviders?: {
    issuer: string;
    clientId: string;
    scope: string;
    label: string;
  }[];

  formGroup = new FormGroup({
    username: new FormControl<string>('', {
      validators: [Validators.email, Validators.required],
      nonNullable: true,
    }),
    password: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });
  displayOauthError: boolean = false;

  constructor(
    private authService: AuthService<CompanyUser, ICompanyUser>,
    private router: Router,
    private route: ActivatedRoute,
    private oauthService: OAuthService,
    private sharedVariablesService: SharedVariablesService
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((qPM) => {
      if (qPM.has('origin')) {
        this.redirect = qPM.get('origin')!;
      }
    });
    if (this.sharedVariablesService.hasVar('oauthProviders')) {
      this.oauthProviders =
        this.sharedVariablesService.getVar('oauthProviders');
      if (
        this.oauthProviders &&
        this.oauthProviders.length &&
        sessionStorage.getItem('selectedOauthProviderIdx') !== null
      ) {
        this.initOAuth(
          Number.parseInt(sessionStorage.getItem('selectedOauthProviderIdx')!)
        );
      }
    }
  }

  login() {
    if (this.formGroup.valid) {
      this.authService.login(this.formGroup.getRawValue()).subscribe({
        next: (user: ICompanyUser) => {
          if (user !== null) {
            this.router.navigateByUrl(this.redirect || '');
            this.redirect = '';
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
          if (error.status === 401) {
            this.formGroup.setErrors({ invalidCreds: true });
          } else {
            this.formGroup.setErrors({ loginFailed: true });
          }
        },
      });
    }
  }

  oauthLogin(providerIdx: number): void {
    if (this.oauthProviders && this.oauthProviders.length) {
      this.initOAuth(providerIdx);
      this.oauthService.initCodeFlow('/login');
    }
  }

  private initOAuth(idx: number) {
    if (this.oauthProviders && this.oauthProviders.length) {
      sessionStorage.setItem('selectedOauthProviderIdx', String(idx));
      this.oauthService.configure({
        issuer: this.oauthProviders[idx].issuer,
        clientId: this.oauthProviders[idx].clientId,
        scope: this.oauthProviders[idx].scope,
        redirectUri: window.location.origin + '/login',
        responseType: 'code',
        strictDiscoveryDocumentValidation: false,
        showDebugInformation: !environment.production,
      });
      this.oauthService
        .loadDiscoveryDocumentAndTryLogin({
          onLoginError: (err) => {
            console.log(err);
            this.displayOauthError = true;
          },
        })
        .then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            this.authService
              .loginWithOAuthToken(
                this.oauthService.getAccessToken(),
                '/oauth2/token'
              )
              .subscribe({
                next: (user: ICompanyUser) => {
                  if (user !== null) {
                    this.router.navigateByUrl(this.redirect || '');
                    this.redirect = '';
                  }
                },
                error: (error: HttpErrorResponse) => {
                  console.error(error);
                  this.formGroup.setErrors({ oauthError: true });
                },
              });
          }
        });
    }
  }

  protected readonly environment = environment;
}
