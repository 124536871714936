import { HttpInterceptorFn } from '@angular/common/http';
import { inject, LOCALE_ID } from '@angular/core';

export const languageInterceptor: HttpInterceptorFn = (req, next) => {
  const localeId = inject(LOCALE_ID);
  const modifiedReq = req.clone({
    setHeaders: {
      'Accept-Language': getAcceptLanguageLocaleId(localeId),
    },
  });
  return next(modifiedReq);
};

function getAcceptLanguageLocaleId(locale: string): 'hu-HU' | 'en-US' {
  if (locale == 'hu') {
    return 'hu-HU';
  } else if (locale == 'en') {
    return 'en-US';
  } else {
    return 'hu-HU'; // Default to Hungarian if no language is specified in the URL
  }
}
